@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: $purple;
  color: $white;
  border-radius: 24px;
  padding: 32px;
  margin: 20px;
  max-width: 690px;
  max-height: 730px;
  width: 100%;
  gap: 20px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background: $purpleTransparent;
  align-items: center;
  display: flex;
  justify-content: center;
  backdrop-filter: blur(10px);
}

.modal_close_button {
  position: absolute;
  right: 14px;
  top: 14px;
}
