@import "src/assets/styles/palette";
@import "src/assets/styles/mixins";

.partner_page {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.top_container {
  width: 100%;
  display: flex;
  gap: 24px;

  @include laptop {
    flex-direction: column;
  }
}

.referral_code_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}
