@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.news_container {
  margin-bottom: 24px;
  position: relative;
  @include tablet {
    margin-bottom: 16px;
  }

  .news_top {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    margin-bottom: 24px;
    .news_card_date {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: $textColorWhite;
    }
  }
  .news_card_img {
    width: 100%;
    height: 300px;
    margin-bottom: 16px;
    border-radius: 24px;
    @include tablet {
      margin-bottom: 12px;
    }
  }
}

.news_back {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 12px;
  @include tablet {
    margin-bottom: 4px;
  }
  .news_back_img {
    transform: rotate(90deg);
  }
  .news_back_text {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: $textColorWhite;

  }
}

.news_no {
  display: flex;
  justify-content: center;
  font-size: 24px;
}

.share {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 48px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: $textColorWhite;
  @include tablet {
    margin-top: 32px;
    flex-direction: column;
  }
  .socials {
    display: flex;
    gap: 8px;
    .socials_icon {
      padding: 8px;
      border-radius: 20px;
      display: flex;
      background-color: $white;
    }
  }
}

.news_view {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0;
  color: $textColorWhite;

  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0;
    color: $textColorWhite;
    margin-bottom: 8px;
    word-break: break-word;
    @include tablet {
      font-size: 14px;
    }
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0;
    margin-bottom: 12px;
    @include tablet {
      font-size: 24px;
    }
  }
  h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0;
    @include tablet {
      font-size: 18px;
    }
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0;
    text-align: left;
    @include tablet {
      font-size: 14px;
    }
  }
  li, ol {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0;
    color: $textColorWhite;
    &::marker {
      color: red;
    }
    @include tablet {
      font-size: 14px;
    }
  }
  img {
    border-radius: 24px;
    width: 100%!important;
    height: auto!important;
  }
}
