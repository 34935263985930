@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.table {
  width: 100%;
  border-spacing: 0 4px;
  border: 1px solid;
  border-radius: 20px;
  background: $purpleDiagonalGradient;
  border: $transparent;
  color: $textColorWhite;
  thead {
    position: relative;
    padding: 2px;
    border-radius: 16px;
    background: $purpleTransparent;

    th {
      height: 64px;
      padding: 8px;
      text-align: center;
      &:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        text-align: left;
      }
      &:last-child {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      @include mobile {
        height: 54px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  td {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    padding: 8px;
    height: 90px;
    border-bottom: 1px solid $white1;
    &.td_gray {
      border-bottom: none;
    }
    &.td_black {
      border-bottom: 1px solid $blueDark;
      padding: 4px 40px;
    }
    &.td_no_data {
      height: 600px;
      .td_no_data_div {
        display: flex;
        gap: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        text-align: center;
        @include tablet {
          font-size: 14px;
          gap: 16px;
        }
      }
    }

    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: center;
      text-align: -webkit-center;
    }
    @include mobile {
      height: 58px;
      font-size: 14px;
      line-height: 21px;
    }
    button {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 14px;
    }
  }

  th {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    text-align: left;
  }

  tr {
    text-align: left;
    &:last-child td {
      border-bottom: none;
    }
  }

}

.table_home {
  box-shadow: $boxShadow;
  border-image-source: $gradientBorderColor;
  background: $gradientBlueGray02Color;
  background-color: $violet;
  padding: 0 4px;
  thead {
    box-shadow: $boxShadow;
    background: $gradientBlueGray00Color;

    th {
      color: $white;
      height: 80px;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      &:first-child {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        text-align: left;
      }
      &:last-child {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        text-align: right;
      }
    }
  }
  td {
    height: 84px;
    border: 1px solid;
    color: $white;
    border-image-source: $gradientBorderColor;
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      text-align: left;
      font-size: 18px;
    }
    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      text-align: right;
    }
    @include mobile {
      height: 58px;
      font-size: 14px;
      line-height: 21px;
    }
  }
}
