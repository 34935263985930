@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.deposit_container {
  // overflow-y: auto;
  @include scrollbar($transparent, $transparent, $transparent);
}

.withdraw_container {
  overflow: auto;
  width: 100%;
  scrollbar-width: none;
}

.deposit_img {
  width: 118px;
  height: 118px;
  @include tablet {
    width: 70px;
    height: 70px;
  }
}

.deposit_title {
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  margin-bottom: 24px;
  @include tablet {
    font-size: 18px;
    margin-bottom: 16px;
  }
}

.deposit_input {
  margin-bottom: 16px;
  font-size: 12px;
  width: 100%;
  @include tablet {
    margin-bottom: 12px;
  }
}

.deposit_input_text {
  text-align: center;
  font-weight: 700;
  font-size: 14px;
}

.ticket_form_drop_text {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: $blueLight;
  margin: 16px 0;
}

.label_check {
  color: $violet;
  @include tablet {
    white-space: pre-wrap;
    background: $gradientTextColor;
    -webkit-background-clip: text;
    -webkit-text-fill-color: $transparent;
  }
}

.withdraw_button {
  margin-top: 16px;
}