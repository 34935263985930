@import "src/assets/styles/palette";
@import "src/assets/styles/mixins";

.lang_controls_menu {
  z-index: 3;
  position: absolute;
  top: 100%;
  left: -10px;
  background-color: $selectBackground;
  backdrop-filter: blur(5px);
  gap: 16px;
  box-shadow: $boxShadow;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: fit-content;
  padding: 12px;
}

.lang_controls_menu_label {
  padding: 16px;
  width: 100%;
  gap: 20px;
  bottom: 100%;
  top: auto;
  max-height: 390px;
  overflow: auto;
}

.lang_controls_lang {
  position: relative;
  &:hover {
    background-image: linear-gradient(
      90deg,
      #341d6a 0%,
      #f7ccf9 44%,
      #341d6a 100%
    );
    background-size: 80% 1px;
    background-repeat: no-repeat;
    background-position: bottom;
  }
}

.lang_controls_lang_label {
  height: auto;
  border: none;
  border-radius: 12px;
}

.lang_controls {
  display: flex;
  align-items: center;
  gap: 12px;
  &_label {
    display: flex;
    gap: 16px;
    border-radius: 12px;
    width: 100%;
  }

  > button {
    width: 24px;
    height: 24px;
  }

  > button > img {
    width: 100%;
    height: 100%;
  }
  // &_active {
  //   background-color: $white2;
  // }
}

.lang_label {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}
