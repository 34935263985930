@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.sidebar_navigation {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sidebar_navigation_link {
  padding: 8px 16px;
  display: flex;
  gap: 16px;
  border-radius: 18px;
  &:hover {
    background-image: $hoverGradient;
    background-size: 80% 1px;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  @include tablet {
    border-radius: 8px;
  }
}

.sidebar_navigation_link_text {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.active {
  background-color: $pink;
  &:hover {
    background-image: none;
  }
}
