@import 'src/assets/styles/palette';

.button {
  position: relative;
  min-height: 48px;
  padding: 13px 32px 14px 32px;
  border-radius: 18px;
  font-size: 16px;
  font-weight: 500;
  color: $textColorWhite;
  text-align: center;
  transition: background, box-shadow 0.3s;
  &:hover {
    box-shadow: unset;
  }

  img {
    margin-left: 16px;
  }

  .children_while_loading {
    opacity: 0;
  }

  &.full_width {
    width: 100%;
  }
  .button_content {
    // display: flex;
  }
}
.bg_transparent {
  background: $transparent;
  border: 1px solid $borderColor;
  box-shadow: none;
  &:hover {
    border: 1px solid $white;
  }
}

.big_height {
  height: 64px;
}

.gray_color {
  background:
    linear-gradient(0deg, $white2, $white2),
    linear-gradient(0deg, $gradientBorderWhiteColor, $gradientBorderWhiteColor);
  color: $textColorWhite;
  box-shadow: none;

}

.disabled {
  &:disabled {
    cursor: not-allowed;
    background: $gray !important;
    color: $textColor;
    box-shadow: none;
  }
}
