@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.faq_container {
  margin-bottom: 48px;
  @include laptop {
    margin-bottom: 32px;
  }
  @include tablet {
    margin-bottom: 24px;
  }
}

.faq_top {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
  @include tablet {
    flex-direction: column;
    align-items: start;
  }
  .faq_top_title {
    margin-bottom: 0;
  }

  .faq_top_select {
    @include tablet {
      width: 100%;
    }
  }
}

.faq_cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include tablet {
    gap: 12px;
  }
  &_item {
    background: $white;
    &_title {
      padding: 24px;
    }
    &_subtitle {
      padding: 24px;
      color: $blueLight;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;

    }
  }
}

.faq_page_container {
  background: $purpleDiagonalGradient;
  border-radius: 32px;
  padding: 32px;

  @include tablet {
    padding: 16px;
  }
}