@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.layout {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: grid;
  background-image: url('../../assets/images/mainBG.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  grid-template-columns: 270px 1fr;
  // grid-template-rows: auto 1fr;
  grid-template-areas: 'sidebar main';
  padding: 40px;
  gap: 50px;
  @include laptop {
    padding: 16px;
    gap: 32px;
  }
  @include tablet {
    grid-template-columns: 1fr;
    grid-template-areas: 'header' 'main';
    overflow: initial;
    padding: 0;
  }
}

.layout_top {
  grid-area: layout_top;
  position: absolute;
  top: 0;
}

.layout_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  @include scrollbar($transparent);
  overflow-y: scroll;
  grid-area: main;
  // padding: 40px 40px 40px 0;
  width: 100%;
  @include laptop {
    overflow-y: auto;
    padding: 0 16px;
  }
}

.layout_wrapper_container {
  width: 100%;
  // padding: 48px 64px;
  border-radius: 40px;
  min-height: 100%;
  @include desktop {
    max-width: 1900px;
  }
  @include laptop {
    border-radius: 0;
    // padding: 40px 24px;
    max-width: 1090px;
  }
  @include tablet {
    // padding: 32px 12px;
  }
}
