@import "src/assets/styles/palette";
@import "src/assets/styles/mixins";

.profile_container {
  margin-bottom: 48px;
  @include laptop {
    margin-bottom: 32px;
  }
  @include tablet {
    margin-bottom: 24px;
  }
}

.profile_form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.profile_form_button_container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.profile_white_containers {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @include laptop {
    flex-direction: column;
  }
}

.profile_white_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  height: 100%;
}

.user_verification_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: none;
  background: $inputBackground;
  color: $textColorWhite;
  border-radius: 24px;
  outline: none;
  outline-offset: none;
  align-items: center;
  width: 100%;
  height: 100%;

    p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }
}