@import "src/assets/styles/palette";
@import "src/assets/styles/mixins";

.statistics_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.statistics_top {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
  .statistics_top_title {
    margin-bottom: 0;
  }
}

.statistics_top_control {
  height: 48px;
  width: max-content;
}

.statistics_cards_list {
  display: grid;
  gap: 8px;
  background: $purpleDiagonalGradient;
  color: $textColorWhite;
  border-radius: 24px;
  box-shadow: $boxShadowMain;
  padding: 32px;
  width: 100%;
  height: -webkit-fill-available;
  grid-template-rows: repeat(auto);
  grid-template-columns: repeat(3, auto);

  @include tablet {
    border-radius: 16px;
    padding: 16px;
    grid-template-rows: auto;
    grid-template-columns: repeat(2, auto);
  }
}

.statistics_cards_item {
  box-shadow: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  height: 150px;
}

.statistics_cards_item_img {
  width: 35px;
  height: 35px;
}

.statistics_cards_item_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: $textColorWhite;
  text-align: center;
  @include tablet {
  }
}

.statistics_cards_item_bottom {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
}

.statistics_cards_item_count {
  font-size: 18px;
  font-weight: 700;
  line-height: 36px;
  color: $textColorWhite;
}

.statistics_cards_item_percent {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  color: $orange;
  border: solid 1px $orange;
  padding: 8px 12px;
  border-radius: 6px;
  &_success {
    color: $green;
    border: solid 1px $green;
  }
}
