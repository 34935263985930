@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.tabs {
  .tabs_titles {
    width: fit-content;
    border-radius: 12px;
    background-color: $inputBackground;
    display: flex;
    padding: 2px;
    
    .tabs_titles_title {
      list-style: none;
      padding: 12px 48px;
      border-radius: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $textColorWhite;
      cursor: pointer;
      display: flex;
      align-items: center;
      outline: none;

      @include laptop {
        width: 100%;
        padding: 12px;
      }
    }
    .tabs_titles_title_active_forever {
      background: $pink;
    }
    .tabs_titles_title_active {
      background: $pinkDarkest;
    }

  }
}
