@import "src/assets/styles/palette";
@import "src/assets/styles/mixins";

.sidebar {
  display: flex;
  flex-direction: column;
  padding: 24px;
  color: $textColorWhite;
  background-color: $purpleTransparent;
  border-radius: 50px;
  grid-area: sidebar;
  width: 100%;
  max-height: calc(100vh - 30px);
  height: fit-content;
  overflow: auto;
  scrollbar-width: none;

  @include tablet {
    background-color: $purple;
    width: 100%;
    height: calc(100vh - 130px);
    position: absolute;
    top: 130px;
    border-radius: 30px 30px 0 0;
    padding: 32px;
  }

  @include mobile {
    top: 90px;
    height: calc(100vh - 90px);
  }
}

.header_logo_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include tablet {
    display: none;
  }
}

.sidebar_logo {
  height: 100%;
  width: 125px;
}

.sidebar_balance {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 24px;
  padding: 16px 24px;
  margin-top: 40px;
  box-shadow: 0px 4px 11px -1px rgba(155, 122, 172, 0.4);
  @include tablet {
    margin: 0;
  }
}

.sidebar_balance_text {
  color: $textColorWhite;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
}

.sidebar_id {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: $textColorWhite;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
}

.sidebar_menu {
  display: flex;
  gap: 16px;
  border-radius: 12px;
  width: 100%;
}

.sidebar_menu_text {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.sidebar_bottom {
  position: sticky;
  border-top: 1px solid $purpleSeparator;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 16px 0;
  padding: 24px 16px 8px 16px;
  @include tablet {
    border: none;
    margin: 0;
  }
}
