@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.partner_content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.partner_copy_link_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  background: $inputBackground;
  padding: 16px 24px;
  border-radius: 24px;

  span {
    color: $pinkDark;
  }

  p {
    font-weight: 500;
    word-break: break-word;
  }

  @include mobile {
    flex-direction: column;
  }
}

.partner_copy_label_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.partner_copy_btn {
  background: $blueButton;
  cursor: pointer;
  padding: 10px 24px;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  border-radius: 18px;
  max-width: 174px;
  width: 100%;
  font-weight: 500;

  @include mobile {
    max-width: 100%;
  }
}