@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.checkbox_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid $textColorWhite;
    position: relative;
    transition: all 0.5s;

    .checkbox_icon_checked {
      opacity: 1;
    }
  }
  .checkbox_icon {
    position: relative;
    width: 12px;
    height: 12px;
    transition: all 0.3s;

    &::before {
      content: "\2714";
      font-size: 12px;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.3s;
    }

    &.checkbox_icon_checked::before {
      opacity: 1;
    }
  }

  .checkbox_checked_icon {
    opacity: 1;
  }

  .label {
    margin-left: 8px;
    font-size: 12px;
    line-height: 18px;
  }
}
