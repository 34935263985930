@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.container {
  background: $purpleDiagonalGradient;
  color: $textColorWhite;
  border-radius: 24px;
  box-shadow: $boxShadowMain;
  padding: 32px;
  width: 100%;
  @include tablet {
    border-radius: 16px;
    padding: 16px;
  }
}
