@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.instruments_container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .instruments_content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    @include tablet {
      flex-direction: column;
    }

    .instruments_content_top {
      display: flex;
      align-items: center;
      .instruments_content_title {
        height: 48px;
        align-content: center;
      }
    }

    .instruments_item {
      @include tablet {
        text-align: center;
      }
    }
    .instruments_img {
      width: 100%;
      border-radius: 16px;
    }
    .instruments_title {
      padding: 0 24px;
      font-weight: 700;

      @include laptop {
        padding: 0 16px;
      }
    }
    .instruments_btn {
      padding: 8px;
      font-size: 14px;
      border-radius: 8px;
      background: $pink;
      display: flex;
      width: 100%;
      justify-content: center;
      font-weight: 500;
    }
    .instruments_btn_container {
      padding: 0 24px 24px 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      @include laptop {
        padding: 0 16px;
      }
    }
    .instruments_lang {
      flex-direction: row;
      top: 0;
      left: 100%;
      @include tablet {
        flex-direction: column;
        top: 100%;
        left: 0;
      }
    }
  }
}

.instruments_banner_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.instruments_top_info {
  display: flex;
  gap: 24px;
}

.tools_content_container {
  width: 100%;
  display: flex;
  gap: 24px;
}

.instruments_item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  max-width: 400px;
  padding: 0 !important;

  @include laptop {
    max-width: 200px;
    padding-bottom: 16px !important;
  }

  @include tablet {
    max-width: 100%;
  }
}

.tools_content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
