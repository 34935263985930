@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.header {
  z-index: 2;
}

.header_fixed {
  position: sticky;
  top: 0;
  width: 100%;
  background: linear-gradient(266.09deg, rgba(93, 156, 255, 0.2) 1.15%, rgba(93, 156, 255, 0) 98.69%);
  box-shadow: $boxShadow;
  backdrop-filter: blur(12px);
}

.header_container {
  max-width: 1248px;
  margin: 0 auto;
  height: 88px;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  @include tablet {
    padding-top: 0;
    height: 64px;
  }
  @include mobile {
    margin: 10px;
  }
}

.header_container_fixed {
  padding-top: 0;
  height: 64px;
  transition: height .3s, padding 0.3s;
}

.header_content {
  display: flex;
  gap: 80px;
  align-items: center;
}

.header_controls {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header_controls_burger {
  height: 48px;
  width: 48px;
  border: 1px solid $borderColor;
  border-radius: 8px;
}
