@import 'src/assets/styles/palette';
@import 'src/assets/styles/mixins';

.ticket_container {
  margin-bottom: 48px;
  @include laptop {
    margin-bottom: 32px;
  }
  @include tablet {
    margin-bottom: 24px;
  }
}

.ticket_form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  @include tablet {
    margin-bottom: 16px;
  }
}

.ticket_form_select {
  height: 64px;
  @include tablet {
    width: 100%;
  }
}

.ticket_btn {
  display: flex;
}

.ticket_form_drop_text {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.dropzone_avatar_ticket {
  padding: 4px 4px 4px 20px;
  height: 64px;
  border-radius: 24px;
  background: $inputBackground;
  @include tablet {
    flex-direction: row;
  }
}
